import { FormControl, TextField, Select, MenuItem, InputLabel, Input, Link, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { alpha, styled } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';

import React, { useEffect, useState } from 'react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineSearch } from 'react-icons/ai';

import type { PageInfo } from '@/generated/graphql';

import { ChangePageType } from '@/shared/enums';

import type { IDrawAdminQueryVariable, IDrawAdminTable } from '@/shared/interfaces';

import type { SetStateAction } from 'react';

// components
import { TableBodySkeleton } from '@/components/table/table-body-skeleton';

type Order = 'asc' | 'desc';

interface TablePageLabel {
  start: number;
  end: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IDrawAdminTable) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IDrawAdminTable;
  label: string;
  numeric: boolean;
  className: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    className: 'agent-code-column',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Username',
    className: 'namecolumn',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Created On',
    className: 'contactnum-column',
  },
];

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

const StyledSelect = styled(Select)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans,Roboto',
    fontWeight: 600,
    fontSize: 15,
    pt: 15,
    pb: 15,
    pl: 15,
  },
  '& .MuiTableCell-hover	': {
    backgroundColor: '#C2DAF0',
    border: '0.08em solid #C2DAF0',
  },
  // '&:nth-of-type(odd)': {
  //   backgroundColor: '#E8F0F7',
  //   fontFamily: 'Poppins',
  // },
  // '&:nth-of-type(even)': {
  //   backgroundColor: '#E0EAF5',
  //   fontFamily: 'Poppins',
  // },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IDrawAdminTable) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: '0.08em solid #ccc', boxShadow: '0em 0.18em 0.4em #bbb' }}>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: '600', lineHeight: '1.2', color: '#555' }}
            className={headCell.className}
            sx={{ pl: 2, py: 1 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={6} align="right" width={140}></TableCell>
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  const [searchWord, setSearchWord] = useState<string>();

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        Operators
      </Typography>

      <FormControl sx={{ m: 1, width: '30ch' }} variant="standard">
        <InputLabel htmlFor="standard-adornment-search">Search</InputLabel>
        <Input
          id="standard-adornment-search"
          onChange={(e) => {
            setSearchWord(e.target.value);
          }}
          onKeyDown={(event) => event.key === 'Enter' && searchHandler(searchWord ? searchWord : '')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => searchHandler(searchWord ? searchWord : '')}
                // onMouseDown={handleMouseDownPassword}
              >
                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                <AiOutlineSearch />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      {/* <FormControl sx={{ ml: 2, mb: 2, mt: 1, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="outlined"
          size="small"
        />
      </FormControl> */}
    </Toolbar>
  );
};
interface lDrawAdminsProps {
  rows: readonly IDrawAdminTable[];
  setDrawAdminQueryVariable: React.Dispatch<SetStateAction<IDrawAdminQueryVariable>>;
  drawAdminQueryVariable: IDrawAdminQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;

  drawAdminEvent: (data: IDrawAdminTable, eventName: string) => void;
  getDrawAdminLoading: boolean;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

export function TableDrawAdmins(props: lDrawAdminsProps) {
  const navigate = useNavigate();

  const { rows, drawAdminQueryVariable, setDrawAdminQueryVariable, totalCount, pageInfo, drawAdminEvent, getDrawAdminLoading } = props;
  const [search, setSearch] = useState('');

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IDrawAdminTable>('name');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page] = useState(0);
  const [dense] = useState(true);
  const [rowsPerPage] = useState(5);
  const [pageLabel, setPageLabel] = useState<TablePageLabel>({
    start: 1,
    end: drawAdminQueryVariable.first,
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IDrawAdminTable) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearch(value);

    console.log(value, '==');

    setDrawAdminQueryVariable({ ...drawAdminQueryVariable });

    // type IDrawAdminKey = keyof IDrawAdminTable;
    // const searchKeys: IDrawAdminKey[] = ['uniqueCode', 'fullName', 'drawAdminType', 'contactNumber', 'username'];

    // const newDrawAdmin = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    // setSearchDrawAdmin(newDrawAdmin);
  };

  const handleChangePage = (type: ChangePageType) => {
    if (type === ChangePageType.NEXT && pageInfo?.endCursor) {
      // next
      setDrawAdminQueryVariable({
        ...drawAdminQueryVariable,
        after: pageInfo.endCursor,
        before: undefined,
      });
      let end = pageLabel.end + drawAdminQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start + drawAdminQueryVariable.first, end });
    } else if (type === ChangePageType.PREV && pageInfo?.startCursor) {
      // prev
      setDrawAdminQueryVariable({
        ...drawAdminQueryVariable,
        before: pageInfo.startCursor,
        after: undefined,
      });
      let end = pageLabel.end - drawAdminQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start - drawAdminQueryVariable.first, end });
    }
  };

  const handleChangeRowsPerPage = (value: string | number) => {
    const intValue = parseInt(value.toString(), 10);

    setDrawAdminQueryVariable({
      ...drawAdminQueryVariable,
      first: intValue,
    });
  };

  const goToDrawAdminPage = (id: string) => {
    navigate(`/dashboard/drawAdmins/${id}`);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#ffffff',
        }}>
        <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
        <TableContainer>
          <Table
            sx={{
              minWidth: 850,
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {getDrawAdminLoading ? (
              <TableBodySkeleton row={10} column={7} />
            ) : (
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      // hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          pl: 2,
                        }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: '600', color: '#6b6b6b' }}>
                        {row.username}
                      </TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                      <TableCell>
                        <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
                          <InputLabel id="demo-select-small" sx={{ fontSize: '.7rem !important' }}>
                            Options
                          </InputLabel>
                          <Select labelId="demo-select-small" id="select-small" label="Options">
                            <MenuItem onClick={() => drawAdminEvent(row, 'edit')}>Edit</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            backgroundColor: '#6591BD',
            color: '#FFFFFF',
            mt: 1,
            justifyContent: 'end',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Typography variant="body2" mr={4} gutterBottom>
            Rows per page:
          </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Select
              onChange={(e) => {
                handleChangeRowsPerPage(e.target.value);
              }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={drawAdminQueryVariable.first}
              label="Row">
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body2" mx={4} gutterBottom>
            {pageLabel.start} - {pageLabel.end} of {totalCount}
          </Typography>
          <IconButton
            onClick={() => handleChangePage(ChangePageType.PREV)}
            disabled={pageInfo?.hasPreviousPage ? false : true}
            size={'small'}
            sx={{ mr: 2 }}
            aria-label="prev">
            <IoIosArrowBack />
          </IconButton>
          <IconButton
            onClick={() => handleChangePage(ChangePageType.NEXT)}
            disabled={pageInfo?.hasNextPage ? false : true}
            size={'small'}
            aria-label="next">
            <IoIosArrowForward />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
}
